import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Chat from "../chat/chat";
import MyDay from "../myDay/myDay";
import Questionnaire from "../questionnaire/questionnaire";
import Modal from "../modal/Modal";
import TermsPopup from "../termsPopup/termsPopup";
import {
  useGetMyDayInfoQuery,
  useLazyGetUserInfoByIdQuery,
} from "../../services/api/api";
import ChatPage from "../../pages/chatPage/chatPage";
import Loader from "../loader/loader";

function App() {
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const uid = searchParams.get("bpreven");
  const questionnaire = searchParams.get("q");
  const [getUserInfo] = useLazyGetUserInfoByIdQuery();
  const { isLoading } = useGetMyDayInfoQuery({ id: uid }, { skip: !uid });
  const [userName, setUserName] = useState<string>("");
  useEffect(() => {
    if (uid) {
      getUserInfo(uid).then((res) => {
        if ("data" in res && res.data) {
          setUserName(res.data.firstName);
        }
      });
    }
  }, [uid, getUserInfo]);

  if (isLoading) return <Loader />;
  return (
    <Router>
      <Routes>
        <Route
          path={"/:id?"}
          element={
            <section className={`section`}>
              {uid && !questionnaire ? (
                <>
                  <Questionnaire
                    link={`?bpreven=${uid}&q=26`}
                    userName={userName}
                  />
                  <MyDay />
                </>
              ) : (
                <>
                  <h1 className={"section__title"}>ונצ'י כאן בשבילך</h1>
                  <button
                    onClick={() => setOpenTermsModal(true)}
                    className={"section__link"}
                  >
                    לתנאי שימוש באתר
                  </button>
                  <Chat u={uid} />
                </>
              )}
              {openTermsModal && (
                <Modal onClose={() => setOpenTermsModal(false)}>
                  <TermsPopup onClose={() => setOpenTermsModal(false)} />
                </Modal>
              )}
            </section>
          }
        />
        <Route
          path="/Appointment/:appointmentId/:usersId"
          element={<ChatPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
