import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import DOMPurify from "dompurify";
import chatStyles from "./chatTherapist.module.css";
import Message from "../message/message";
import { IChatMessage, IMessage } from "../../utils/types";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import { useAppSelector } from "../../services/hooks";
import { appointmentSelector } from "../../services/selectors";
import { TWSState } from "../../utils/ws.reducer";
import ChatNote from "../chatNote/chatNote";
import TypingNote from "../typingNote/typingNote";
import useTypingIndicator from "../../hooks/useTypingIndicator";

const ChatTherapist = ({
  therapist = { name: "" },
  messages,
  sendJsonMessage,
  userId,
  room,
  chatState,
  isTherapistOnline,
}: {
  therapist: { name: string };
  messages: IMessage[];
  sendJsonMessage: null | ((message: IChatMessage) => void);
  userId: string;
  room: string;
  chatState: TWSState;
  isTherapistOnline: boolean;
}) => {
  const messagesEndRef = useRef(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState("");
  const appointment = useAppSelector(appointmentSelector);
  const { isTyping, startTyping } = useTypingIndicator(4000);

  useAutosizeTextArea(textAreaRef.current, value);
  const handleSendMessage = useCallback(() => {
    if (sendJsonMessage) {
      sendJsonMessage({
        Type: "CHAT",
        Sender: userId,
        Content: value,
        RoomId: room,
        Users: ["therapist.id", userId],
      });
    }
    setValue("");
  }, [sendJsonMessage, userId, value, room]);

  useEffect(() => {
    if (sendJsonMessage) {
      sendJsonMessage({
        Type: "CHAT",
        Sender: userId,
        Content: `TYPING uid${userId} isTyping${isTyping}`,
        RoomId: room,
        Users: ["therapist.id", userId],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" && event.ctrlKey) {
        event.preventDefault();
        setValue((prev) => `${prev}\n`); // Add a new line to the text
      } else if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSendMessage();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSendMessage]);

  const scrollToBottom = () => {
    // @ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);
  const handleInput = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      startTyping();
      let sanitizedContent = DOMPurify.sanitize(e.target.value, {
        ALLOWED_TAGS: [], // Allow only certain tags, or use [] for none
        ALLOWED_ATTR: [], // Allow no attributes
      });
      setValue(sanitizedContent);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const inputPlaceholder = chatState.active
    ? isTherapistOnline
      ? "כתוב הודעה"
      : "אנא המתן עד שהמטפל ייכנס לצ'אט"
    : "פגישה זו אינה פעילה יותר";
  const disabled = !appointment || !appointment.Active || !isTherapistOnline;
  return (
    <div className={chatStyles.container}>
      <div className={chatStyles.nameContainer}>
        <img
          src={avatarPlaceholder}
          alt={therapist.name}
          className={chatStyles.avatar}
        />
        <div className={chatStyles.info}>
          <h1 className={chatStyles.name}>{therapist.name}</h1>
          <p className={chatStyles.online}>
            {isTherapistOnline ? "מחובר" : "לא מחובר"}
          </p>
        </div>
      </div>
      {messages.length ? (
        <div className={chatStyles.messagesWrapper}>
          {messages.map((m, index) => (
            <div key={index} className={chatStyles.dateSection}>
              <Message message={m} isOwnMessage={userId === m.id} />
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      ) : (
        <div className={chatStyles.text}>אין הודעות עדיין</div>
      )}
      {chatState.therapistClosedConnection && (
        <ChatNote text={"פגישה זו אינה פעילה יותר"} />
      )}
      {chatState.isTyping && <TypingNote name={therapist.name} />}
      <div className={chatStyles.replyBar}>
        <textarea
          className={`${chatStyles.message} ${chatStyles.textArea}`}
          ref={textAreaRef}
          rows={1}
          placeholder={inputPlaceholder}
          onChange={handleInput}
          value={value}
          disabled={disabled}
        />
        <button
          className={`${chatStyles.btn} ${chatStyles.sendBtn} ${
            disabled && chatStyles.sendBtn_disabled
          }`}
          onClick={handleSendMessage}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default ChatTherapist;
