import { Dispatch, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { TWSAction } from "../utils/ws.reducer";
import { IChatMessage } from "../utils/types";

export type TWSConfig = {
  url: string;
  shouldConnect: boolean;
  appointmentId: string | undefined;
  dispatch: Dispatch<TWSAction>;
  usersId: string | undefined;
};
const useConditionalWebSocket = ({
  url,
  shouldConnect,
  appointmentId,
  dispatch,
  usersId,
}: TWSConfig) => {
  const [sendMessage, setSendMessage] = useState<
    ((message: IChatMessage) => void) | null
  >(null);

  const { sendJsonMessage } = useWebSocket(
    url,
    {
      shouldReconnect: (closeEvent) => Boolean(closeEvent), // Reconnect based on the condition
      onOpen: () => {
        const storedMessagesString =
          appointmentId && sessionStorage.getItem(appointmentId);
        dispatch({
          type: "STORED_MESSAGES",
          payload: {
            Type: "STORED_MESSAGES",
            Sender: usersId || "",
            Content: storedMessagesString || "",
            RoomId: appointmentId || "",
            Users: [],
          },
        });
      },
      onMessage: (event) => {
        const messageData = JSON.parse(event.data);
        dispatch({ type: messageData.Type, payload: messageData });
      },
      // onClose: (event) => console.log("WebSocket connection closed.", event),
      // onError: (error) => console.error("WebSocket error:", error),
    },
    shouldConnect
  );

  useEffect(() => {
    if (shouldConnect) {
      setSendMessage(() => sendJsonMessage);
    }
  }, [shouldConnect, sendMessage, sendJsonMessage]);

  return { sendMessage };
};

export default useConditionalWebSocket;
