import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../utils/utils";
import {
  IAppointment,
  IUser,
  TGetQuestionByIdResponse,
  TInsertAllAnswersRequest,
  TInsertAnswerRequest,
  TReportData,
  TServerMessage,
} from "../types";

export const api = createApi({
  reducerPath: "utils",
  tagTypes: ["api"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUserInfoById: build.query<IUser, string>({
      query: (id) => ({
        url: `/user/User_GetUserByGuid/${id}`,
        method: "GET",
      }),
    }),
    getMyDayInfo: build.query<
      { Messages: TServerMessage[] },
      { id: string | null }
    >({
      query: ({ id }) => ({
        url: `/MyDay/GetMessagesByUserId/${id}`,
        method: "GET",
      }),
    }),
    getQuestionsReportsByUserId: build.query<
      TReportData,
      { userId: string; cycle: string }
    >({
      query: ({ userId, cycle }) => ({
        url: `Questions/getQuestionsReportsByUserId/26/${userId}/${cycle}`,
        method: "GET",
      }),
    }),
    getQuestionById: build.query<
      TGetQuestionByIdResponse,
      { nextQuestionId: number; skip: boolean }
    >({
      query: ({ nextQuestionId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/26/${nextQuestionId}/0`,
        method: "GET",
      }),
    }),
    getAppointmentById: build.query<
      { Appointment: [IAppointment] },
      string | number
    >({
      query: (id) => ({
        url: `Appointments/Appointments_GetAppointmentById/${id}`,
        method: "GET",
      }),
    }),
    insertAnswerQuestion: build.mutation<
      TGetQuestionByIdResponse,
      TInsertAnswerRequest
    >({
      query: (option) => ({
        url: `/Questions/InsertAnswerQuestion`,
        method: "POST",
        body: JSON.stringify(option),
      }),
    }),
    insertAllAnswersQuestions: build.mutation<
      boolean,
      TInsertAllAnswersRequest[]
    >({
      query: (answers) => ({
        url: `/Questions/InsertAllAnswersQuestions/1`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
    }),
  }),
});

export const {
  useLazyGetQuestionByIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useGetQuestionsReportsByUserIdQuery,
  useLazyGetUserInfoByIdQuery,
  useGetUserInfoByIdQuery,
  useGetMyDayInfoQuery,
  useLazyGetAppointmentByIdQuery,
} = api;
